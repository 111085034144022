import React from "react"
import { graphql } from "gatsby"

import Banner from "../containers/Banner"
import Breadcrumbs from "../containers/Breadcrumbs"
import CaseStudiesPostsList from "../containers/CaseStudiesPostsList"
import Consultation from "../containers/Consultation"
import Layout from "../containers/Layout"
import NewEbook from "../containers/NewEbook"
import Newsletter from "../containers/Newsletter"
import SEO from "../components/SEO"
import { ContentWrapper } from "../containers/alpacked.style"

const CaseStudyIndustryTemplate = ({ data, location, pageContext }) => {
  const { edges } = data.allPrismicCaseStudiesPost

  const {
    title,
    image,
    subtitle,
    seo_description,
    seo_short_description,
    image_link_preview,
  } = data.prismicCaseStudies.data
  return (
    <>
      <SEO
        image={image_link_preview.fluid?.src}
        location={location}
        title={`${pageContext.industries} Case Studies`}
        shortDesc={seo_short_description.text}
        description={seo_description.text}
      />
      <Layout pathname="/case-studies">
        <ContentWrapper>
          <Banner title={title.text} subtitle={subtitle} image={image.url} />
          <Newsletter pathname="/case-studies" />
          <Breadcrumbs page="Case Studies" />
          <NewEbook />
          <CaseStudiesPostsList content={edges} />
          <Consultation pathname="/case-studies" />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default CaseStudyIndustryTemplate

export const caseStudyIndustryQuery = graphql`
  query caseStudyIndustry($industries: String!) {
    prismicCaseStudies {
      data {
        seo_description {
          text
        }
        seo_short_description {
          text
        }
        seo_title {
          text
        }
        image_link_preview {
          fluid {
            src
          }
        }
        title {
          text
        }
        subtitle
        image {
          url
        }
      }
    }
    allPrismicCaseStudiesPost(
      filter: { data: { industries: { eq: $industries } } }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          uid
          id
          tags
          first_publication_date(formatString: "MMM DD, YYYY")
          data {
            industries
            intro_text
            title {
              text
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 72) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
